import axios from "axios";

export function createOTP(data) {
  return axios.post("https://email-auth.chektdev.com/api/send_otp", data);
}

export function login(data) {
  return axios.post("https://email-auth.chektdev.com/api/login", data);
}

export function checkAccount(data) {
  return axios.post("https://email-auth.chektdev.com/api/checkAccount", data);
}

//https://email-auth.chektdev.com/api/send_otp

//https://email-auth.chektdev.com/api/login

//https://email-auth.chektdev.com/api/checkAccount

/////////////

//http://localhost:3000/api/send_otp

//http://localhost:3000/api/login

//http://localhost:3000/api/checkAccount
