<template>
  <div class="timer-container" :class="{ hidden: isTimeOut }">
    <p>
      OTP is expired in {{ minutes }}:{{
        seconds < 10 ? "0" + seconds : seconds
      }}
    </p>
  </div>
  <div class="notice" v-if="isTimeOut">
    <p v-if="isTimeOut">OTP is expired. Please click get new OTP below</p>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
  setup(_, { emit }) {
    const minutes = ref(3);
    const seconds = ref(0);
    let timerInterval;
    const isTimeOut = ref(false);

    onMounted(() => {
      startTimer(); // 페이지가 렌더링되자마자 타이머 시작
    });

    const startTimer = () => {
      timerInterval = setInterval(updateTimer, 1000);
    };

    const stopTimer = () => {
      clearInterval(timerInterval);
    };

    const updateTimer = () => {
      if (seconds.value > 0) {
        seconds.value--;
      } else {
        if (minutes.value > 0) {
          minutes.value--;
          seconds.value = 59;
        } else {
          isTimeOut.value = true;
          stopTimer();
        }
      }
    };

    return {
      minutes,
      seconds,
      isTimeOut,
      stopTimer,
    };
  },
};
</script>

<style>
.timer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: black;
  height: 20px;
}

.notice {
  padding-top: 5px;
  color: rgb(227, 92, 92);
  font-size: 12px;
  height: 20px;
}

.hidden {
  display: none;
}
</style>
