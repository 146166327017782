import { createRouter, createWebHistory } from "vue-router";
import emailView from "@/views/EmailView";
import otpView from "@/views/OtpView";

const routes = [
  {
    path: "/",
    name: "home",
    component: emailView,
  },
  {
    path: "/verify",
    name: "otp",
    component: otpView,
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
});

export default router;
