<template>
  <div class="otp-bx">
    <input
      v-for="(input, index) in inputs"
      :key="index"
      type="tel"
      maxlength="1"
      v-model="input.value"
      @paste="handleOtppaste($event)"
      @keyup="handleInputOtp($event, index)"
      @keydown="handleForNaN($event)"
      :class="{
        dash: index === 3,
        disabled: input.disabled,
      }"
      :disabled="index === 3 || input.disabled"
      :ref="'input' + index"
    />
  </div>
</template>

<script>
export default {
  props: {
    activeInputs: Number,
  },
  data() {
    return {
      inputs: [
        { value: "", disabled: false },
        { value: "", disabled: false },
        { value: "", disabled: false },
        { value: "-", disabled: true },
        { value: "", disabled: false },
        { value: "", disabled: false },
        { value: "", disabled: false },
      ],
    };
  },
  mounted() {
    this.$refs["input0"][0].focus();
    //this.setupOtpFields();
    console.log(this.$route,"난 route")
    console.log(this.$router,"난 라우터")
    console.log(this.$router.query,"난 쿼리")
      console.log('you opt', this.$route.query.otp)
      if (this.$route.query.otp && this.$route.query.otp.length === 6) {
        const otpChars = this.$route.query.otp.split('');
        this.inputs[0].value = otpChars[0]
        this.inputs[1].value = otpChars[1]
        this.inputs[2].value = otpChars[2]
        this.inputs[4].value = otpChars[3]
        this.inputs[5].value = otpChars[4]
        this.inputs[6].value = otpChars[5]
        this.submit()
      } 
    
  },
  watch: {
    activeInputs(newVal) {
      if (newVal) {
        this.inputs.forEach((input, index) => {
          if (index === 3) {
            input.disabled = true;
          } else {
            input.value = "";
            input.disabled = false;
          }
        });
      }
    },
    // "$route": function (newOtp) {
    //   console.log('you opt', newOtp)
    //   if (newOtp && newOtp.length === 6) {
    //     const otpChars = newOtp.split('');
    //     this.inputs[0].value = otpChars[0]
    //     this.inputs[1].value = otpChars[1]
    //     this.inputs[2].value = otpChars[2]
    //     this.inputs[4].value = otpChars[3]
    //     this.inputs[5].value = otpChars[4]
    //     this.inputs[6].value = otpChars[5]
    //   } 
    // }
  },
  methods: {
    handleOtppaste(e) {
      const data = e.clipboardData.getData("text");
      const value = data.split("");

      if (value.length === this.inputs.length - 1) {
        this.inputs.forEach((input, index) => {
          if (index !== 3) {
            if (index < 3) {
              input.value = value[index];
            } else {
              input.value = value[index - 1];
            }
          }
        });
        console.log("handleOtppaste");
        this.submit();
      }
    },
    handleInputOtp(e, index) {
      const input = e.target;
      let value = input.value;
      console.log(value, "@@@!!");

      input.value = "";
      input.value = value ? value[0] : "";

      if (value.length > 0 && value >= 0) {
        if (index < this.inputs.length - 1 && index !== 2) {
          input.nextElementSibling.focus();
        } else if (index === 2) {
          // this.$refs["input3"][0].blur();
          this.$refs["input4"][0].focus();
        }
      }

      // if (e.key === "Tab" && value === "") {
      //   e.preventDefault();
      //   return;
      // }

      if (e.key === "Backspace" && index > 0) {
        if (index === 4) {
          this.$refs["input2"][0].focus();
        }
        input.previousElementSibling.focus();
      }

      if (index === this.inputs.length - 1 && Number(value)) {
        this.submit();
      }
    },
    handleForNaN(e) {
      if ((e.metaKey || e.ctrlKey) && (e.key === "v" || e.key === "ㅍ")) {
        return;
      }

      const input = e.target;
      if ((isNaN(e.key) || e.key === " ") && input.value === "") {
        e.preventDefault();
      }
    },
    // setupOtpFields() {
    //   const otpFromRoute = this.$route.query.otp
    //   console.log(otpFromRoute,"!!!")
    //   if(otpFromRoute && otpFromRoute.length === 6) {
    //     const otpChars = otpFromRoute.split('')
        
    //     console.log(otpChars,">>")
    //     otpChars.forEach((char,index)=> {
    //       this.inputs[index].value = char;
    //     })
    //   }
    // },
    submit() {
      console.log("submitting...!!");
      let inputValue = "";
      let otp;
      this.inputs.forEach((input) => {
        inputValue += input.value;
        otp = inputValue.split("-").join("").trim();
        input.disabled = true;
      });

      console.log(otp);
      this.$emit("hand-over-otp", otp);
    },
  },
};
</script>

<style>
body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
}

.otp-bx {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}

.otp-bx input {
  width: 40px;
  height: 50px;
  font-size: 30px;
  padding: 10px;
  text-align: center;
  border: 1px solid #e8e7e8;
  background: #fff;
  font-weight: bold;
  color: black;
  outline: none;
  transition: all 0.1s;
  z-index: 1000000;
}

.otp-bx input:nth-child(4) {
  border: none;
  outline: none;
  background: none;
}

.otp-bx input:first-child,
.otp-bx input:nth-child(5) {
  border-radius: 10px 0 0 10px;
}

.otp-bx input:nth-child(3),
.otp-bx input:last-child {
  border-radius: 0 10px 10px 0;
}

.otp-bx input:nth-child(2),
.otp-bx input:nth-child(6) {
  border-left: none;
  border-right: none;
}

.otp-bx input:focus {
  border: 2px solid #a6d6f1;
  box-shadow: 0 0 2px 2px #00a2ff59;
}

.dash {
  width: 30px;
  text-align: center;
  font-size: 50px;
}

.disabled {
  opacity: 0.5;
}
</style>
