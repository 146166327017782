<template>
  <form class="form" method="post">
    <div class="header">
      <img class="success_mark" :src="success_mark" />
      <div class="header_text">Email sent successfully!</div>
    </div>
    <div class="notice-messages">
      <div class="resendingSuccess-message" v-if="resendingEmailSuccess">
        {{ resendingEmailSuccess }}
      </div>
      <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
    </div>
    <div class="content">
      <p id="text" class="content text">Enter the 6-digit code to log in.</p>
      <p id="text" class="email">
        send to <span class="emailStyle">{{ email }}</span>
      </p>
      <otpBox @hand-over-otp="checkOtp" :activeInputs="active"></otpBox>
      <timer :key="timerKey" v-if="!stopTimer"></timer>
    </div>
    <div class="bottom">
      <p>
        didn't receive email?
        <button class="getNewOtp" type="button" @click="sendEmail">
          Get a new OTP
        </button>
      </p>
      <p>
        Or,
        <button class="getNewOtp" @click="back($event)">
          try another email to log in.
        </button>
      </p>
    </div>

    <!-- <button class="button" type="submit" method="post">LOG IN</button> -->
  </form>
</template>

<script>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import { login } from "@/api/email";
import otpBox from "@/views/otpBox";
import timer from "@/views/timer";
import { createOTP } from "@/api/email";

export default {
  components: {
    otpBox,
    timer,
  },
  setup() {
    const route = useRoute(); //현재 route
   // const router = useRouter(); // main.js에서 router가 어디로 타고 오는지
    const form = ref({ email: route.query.email, OTP: route.query.otp });
    const email = form.value.email;

    //const cookieDuration = 3 * 60; //min
    const errorMessage = ref(false);
    const resendingEmailSuccess = ref("");
    const resendingEmailCount = ref("1");
    const timerKey = ref(0);
    const stopTimer = ref(false);
    const active = ref(0);

    watch(
      () => route.query.otp,
      (newOtp) => {
        if (newOtp && newOtp.length === 6) {
          const otpChars = newOtp.split('');
          otpChars.forEach((char, index) => {
            form.value.OTP[index] = char;
          });
        }
      }
    )

    const checkOtp = async (otp) => {
      try {
        stopTimer.value = true;
        errorMessage.value = false;
        const userInfo = { email: route.query.email, OTP: otp };
        console.log(userInfo);
        const currentDomain = String(window.location.hostname);
        const res = await login(userInfo);

        const idToken = res.data.id_token;
        
        // Assuming the token payload contains an exp field with expiration time in seconds
        const base64Url = idToken.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        const decodedPayload = JSON.parse(jsonPayload);
        const expiryDate = new Date(decodedPayload.exp * 1000);
        const expires = expiryDate.toUTCString();

        console.log("디코드된 헤더:", expiryDate);
        console.log("디코드된 페이로드:", expires);

        // *쿠기를 설정하는 코드가 실행되는 브라우져의 도메인과 쿠키에 설정하려는 도메인이 같아야 쿠기가 설정됨
        if (currentDomain.includes("chektdev")) {
          document.cookie = `chekt%3A%3Aid_token=${res.data.id_token}; expires=${expires}; domain=chektdev.com; path=/`;
          document.cookie = `chekt%3A%3Aexpires_at=${expiryDate.getTime()}; expires=${expires}; domain=chektdev.com; path=/`;
          document.cookie = `chekt%3A%3Aemail_auth_expires_at=${expiryDate.getTime()}; expires=${expires}; domain=chektdev.com; path=/`;
        } else {
          document.cookie = `chekt%3A%3Aid_token=${res.data.id_token}; expires=${expires}; domain=chekt.com; path=/`;
          document.cookie = `chekt%3A%3Aexpires_at=${expiryDate.getTime()}; expires=${expires}; domain=chekt.com; path=/`;
        }

        const url = new URL(window.location)
        console.log(url, "나는 유알엘")
        const redirect_url_Str = url.searchParams.get("redirect_uri")
        console.log(redirect_url_Str, "나는 리다이렉트 유알엘 스트링")
        const redirect_url = redirect_url_Str?.replace(/['"]/g, '')  
        
        console.log(location, "나는 로케이션")
        console.log(redirect_url, "나는 리다이렉트 유알엘")
        
        // window.location.href =redirect_url
      } catch (err) {
        console.log(err);
        resendingEmailSuccess.value = false;
        errorMessage.value = err.response?.data?.message || "Error";

        if ((errorMessage.value === "INVALID OTP")) {
          active.value++;
          stopTimer.value = false;
        }
      }
    };

    const sendEmail = async () => {
      try {
        errorMessage.value = false;
        resendingEmailSuccess.value = `Re-sending email successfully (${resendingEmailCount.value++})`;
        stopTimer.value = false;
        timerKey.value++;
        active.value++;

        await createOTP({ email: email });
      } catch (err) {
        console.log(form.value, "!!!");
        console.log(err);
        resendingEmailSuccess.value = false;
        errorMessage.value = err.response?.data?.message || "Error";
      }
    };

    const back = (e) => {
      e.preventDefault();
      window.location.replace("https://email-auth.chektdev.com/");
    };

    return {
      form,
      checkOtp,
      email,
      sendEmail,
      back,
      errorMessage,
      resendingEmailSuccess,
      timerKey,
      stopTimer,
      active,
      success_mark:
        "https://cdn.pixabay.com/photo/2016/01/20/18/59/confirmation-1152155_960_720.png",
    };
  },
};
</script>

<style>
/* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
} */

#text {
  padding: 0;
  line-height: 1.8;
  height: auto;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.54);
}

.form {
  box-shadow: 0 0 40px 4px #111118;
  opacity: 1;
  width: 300px;
  margin: 0 auto;
  border-radius: 5px;
  max-height: 100vh;
}

.header {
  background: #eae9ea;
  height: 118px;
  border-radius: 6px 6px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  background: #fff;
  padding: 10px;

  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  text-align: center;
  position: relative;
}

.success_mark {
  background: #e8e7e8;
  height: 58px;
  width: 58px;
  margin: 0 0 11px;
}

.header_text {
  background: #e8e7e8;
  display: block;
  line-height: 30px;
  font-size: 22px;
}

.emailStyle {
  color: rgb(0, 162, 255);
  font-weight: bold;
}

.input {
  margin-top: 15px;
  height: 40px;
  width: 87%;
  padding: 0 14px;
  color: rgba(34, 34, 40, 0.87);
  border: 1px solid #edebeb;
  border-radius: 3px;
}

.input input:focus {
  outline: 1px solid rgb(172, 172, 172);
}

.bottom {
  background: rgb(0, 162, 255);
  width: 100%;
  height: 70px;
  border-radius: 0 0 6px 6px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  padding-top: 15px;
  line-height: 18px;
}

.getNewOtp {
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  color: darkblue;
}

.error-message {
  background: #ff3e00;
  color: #fff;
  text-align: center;
  padding: 10px;
  line-height: 1.8;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
}

.resendingSuccess-message {
  background: green;
  color: #fff;
  text-align: center;
  padding: 10px;
  line-height: 1.8;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
}
</style>
