<template>
  <div class="entire">
    <div class="bg-overlay"></div>
    <div class="bg-centerWidget">
      <RouterView></RouterView>
    </div>
  </div>
</template>

<script>
</script>

<style>
* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.entire {
  display: table;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  /* z-index: 1000000; */
}

.bg-overlay {
  background: radial-gradient(#5a5a5d, #262631) rgba(34, 34, 40, 0.94);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
}

.bg-centerWidget {
  vertical-align: middle;
  display: table-cell;
  margin: auto;
}
/*
vertical-align: middle; :: 셀 내부를 수직 정렬할 수 있다.
inline, table-cell 박스 요소에만 적용된다.

display: table-cell :: 자동으로 수평 가운데 정렬이 된다. , 자동으로 높이 조절이 된다., block 속성 처럼 작용한다.

*/
</style>
